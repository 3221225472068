import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Security from '../../containers/Services/Security';

const title = 'Enterprise Security Services';
const description =
  "In today's digital landscape, security cannot be an afterthought. Our comprehensive security services help organizations build and maintain robust security programs that protect assets, ensure compliance, and enable innovation. With deep technical expertise across cloud platforms, development practices, and security operations, we provide end-to-end security solutions tailored to your organization's needs.";
const securityPhilosophyDetails = {
  title: 'Our Security Philosophy',
  topics: [
    {
      startingText: 'We believe that effective security must be:',
      points: [
        'Integrated into processes, not bolted on',
        'Automated where possible to ensure consistency',
        'Measurable to demonstrate effectiveness',
        'Aligned with business objectives',
        'Implemented by technical experts who understand your context',
      ],
    },
  ],
};
const securityPortfolioDetails = {
  title: 'Our Security Portfolio',
  topics: [
    {
      title: 'Managed Security Services',
      titleHref: '/services/security/mssp',
      startingText:
        'As your Managed Security Service Provider (MSSP), we provide comprehensive security operations coverage:',
      points: [
        '24/7 Security Operations Center (SOC)',
        'Real-time threat detection and response',
        'Infrastructure security management',
        'Security advisory services',
        'Continuous security monitoring',
      ],
      tailingText:
        'Through proactive threat hunting, advanced SIEM capabilities, and expert security analysts, we ensure your systems stay protected against evolving threats.',
    },
    {
      title: 'Compliance and Certification',
      titleHref: '/services/security/compliance',
      startingText:
        'We simplify your journey to security compliance through our partnership with Vanta:',
      points: [
        'SOC 2 certification support',
        'ISO 27001 implementation',
        'CIS Benchmarks alignment',
        'Automated evidence collection',
        'Continuous compliance monitoring',
        'Streamlined audit preparation',
      ],
      tailingText:
        'Our approach combines technical expertise with automation to make compliance sustainable and efficient.',
    },
    {
      title: 'DevSecOps Implementation',
      titleHref: '/services/security/devsecops',
      startingText: 'We help development teams build security into their workflows:',
      points: [
        'Secure development lifecycle implementation',
        'Security tool integration',
        'Automated security testing',
        'Container and cloud security',
        'Security monitoring and protection',
      ],
      tailingText:
        'Our DevSecOps practices ensure security keeps pace with rapid development cycles without creating bottlenecks.',
    },
  ],
};
const technicalExpertiseDetails = {
  title: 'Technical Expertise',
  description: 'Our security professionals bring deep expertise across:',
  topics: [
    {
      title: 'Cloud Platforms',
      points: [
        'Amazon Web Services (AWS)',
        'Microsoft Azure',
        'Google Cloud Platform',
        'Multi-cloud security architecture',
        'Cloud security posture management',
      ],
    },
    {
      title: 'Security Technologies',
      points: [
        'SIEM and log management',
        'Endpoint detection and response',
        'Network security',
        'Identity and access management',
        'Vulnerability management',
      ],
    },
    {
      title: 'Development Security',
      points: [
        'Application security testing',
        'Container security',
        'Infrastructure as Code security',
        'API security',
        'Secure coding practices',
      ],
    },
  ],
};
const ourApproachDetails = {
  title: 'Our Approach',
  description: 'Our security professionals bring deep expertise across:',
  topics: [
    {
      title: '1. Assessment',
      startingText: 'We begin by understanding your:',
      points: [
        'Current security posture',
        'Technical infrastructure',
        'Business objectives',
        'Compliance requirements',
        'Risk tolerance',
      ],
    },
    {
      title: '2. Strategy Development',
      startingText: 'We create a comprehensive security roadmap:',
      points: [
        'Control implementation plans',
        'Tool selection and integration',
        'Process development',
        'Team training requirements',
        'Success metrics',
      ],
    },
    {
      title: '3. Implementation',
      startingText: 'We deliver through:',
      points: [
        'Phased rollout approach',
        'Clear success criteria',
        'Regular progress reviews',
        'Knowledge transfer',
        'Continuous improvement',
      ],
    },
    {
      title: '4. Ongoing Management',
      startingText: 'We provide continuous support through:',
      points: [
        '24/7 monitoring',
        'Regular assessments',
        'Threat hunting',
        'Incident response',
        'Security optimization',
      ],
    },
  ],
};
const whyChooseUsDetails = {
  title: 'Why Choose Us',
  topics: [
    {
      title: 'Technical Depth',
      startingText: 'Our security professionals bring hands-on experience with:',
      points: [
        'Modern cloud architectures',
        'Development practices',
        'Security operations',
        'Compliance frameworks',
        'Security automation',
      ],
    },
    {
      title: 'Comprehensive Coverage',
      startingText: 'We provide end-to-end security solutions:',
      points: [
        'Security operations',
        'Compliance management',
        'Development security',
        'Infrastructure protection',
        'Training and advisory',
      ],
    },
    {
      title: 'Business Alignment',
      startingText: 'We ensure security enables your business:',
      points: [
        'Risk-based approach',
        'Cost-effective solutions',
        'Efficiency through automation',
        'Measurable outcomes',
        'Clear communication',
      ],
    },
  ],
};
const gettingStartedDetails = {
  title: 'Getting Started',
  description: [
    "Every organization's security journey is unique. Whether you need comprehensive security operations, compliance support, or secure development practices, our team can help you build and maintain a robust security program.",
    'Contact our security team to discuss your specific needs and how we can help protect your organization while enabling innovation and growth.',
  ],
};

const details = {
  title,
  description,
  securityPhilosophyDetails,
  securityPortfolioDetails,
  technicalExpertiseDetails,
  ourApproachDetails,
  whyChooseUsDetails,
  gettingStartedDetails,
};

const propTypes = {
  data: PropTypes.shape({
    contentfulCompanyQuote: PropTypes.shape({
      footerDescription: PropTypes.shape({}).isRequired,
    }),
  }).isRequired,
};

const SecurityPage = ({ data }) => (
  <Security {...details} companyQuote={data.contentfulCompanyQuote} />
);
SecurityPage.propTypes = propTypes;

export default SecurityPage;

export const query = graphql`
  query {
    contentfulCompanyQuote(label: { eq: "servicesAws" }) {
      footerDescription {
        json
      }
    }
  }
`;
