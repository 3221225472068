import PropTypes from 'prop-types';
import React from 'react';

import { getSeoConfig } from '../../../constants/defaultSeoProps';
import Layout from '../../../components/Layout';
import SecurityPageHeroImg from '../../../assets/services/SecurityPageHeroImage.png';
import ServiceSection from '../../../components/ServiceSection';
import s from './Security.module.scss';

const propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  securityPhilosophyDetails: PropTypes.shape({}).isRequired,
  securityPortfolioDetails: PropTypes.shape({}).isRequired,
  technicalExpertiseDetails: PropTypes.shape({}).isRequired,
  ourApproachDetails: PropTypes.shape({}).isRequired,
  whyChooseUsDetails: PropTypes.shape({}).isRequired,
  gettingStartedDetails: PropTypes.shape({}).isRequired,
  companyQuote: PropTypes.shape({
    footerDescription: PropTypes.shape({}).isRequired,
  }).isRequired,
};

const seoParams = {
  ogImagePath: '/og-images/security-services.png',
  ogTitle: 'Enterprise Security Services | Tech Holding',
  ogDesc:
    'Comprehensive security solutions including managed security operations, compliance automation, and secure development practices. Protect your assets while enabling innovation.',
  ogPath: '/services/security',
};

const updatedSeo = getSeoConfig(seoParams);

const Security = (props) => {
  const {
    title,
    description,
    securityPhilosophyDetails,
    securityPortfolioDetails,
    technicalExpertiseDetails,
    ourApproachDetails,
    whyChooseUsDetails,
    gettingStartedDetails,
    companyQuote,
  } = props;

  return (
    <Layout
      currentPage="/services/security"
      seo={updatedSeo}
      title={updatedSeo.openGraph.title}
      footerDescription={companyQuote.footerDescription}
    >
      {/* Header */}
      <div className={s.headerContainer}>
        <div className={s.header}>
          <h1>{title}</h1>
        </div>
      </div>

      <div className={s.pageContainer}>
        {/* Description */}
        <p className={s.description}>{description}</p>

        {/* Security Philosophy */}
        <ServiceSection {...securityPhilosophyDetails} />

        {/* Hero Image */}
        <img src={SecurityPageHeroImg} alt={title} />

        {/* Security Portfolio */}
        <ServiceSection {...securityPortfolioDetails} />

        {/* Technical Expertise */}
        <ServiceSection {...technicalExpertiseDetails} />

        {/* Our Approach */}
        <ServiceSection {...ourApproachDetails} />

        {/* Why Choose Us */}
        <ServiceSection {...whyChooseUsDetails} />

        {/* Getting Started */}
        <ServiceSection {...gettingStartedDetails} />
      </div>
    </Layout>
  );
};

Security.propTypes = propTypes;
export default Security;
